<template>

</template>

<script>
    import api from '../api/oa'
    import Utils from '../utils/utils'
    export default {
        name: "callBack.vue",
        data(){
            return {
                token:'',
                sid:'',
                loginNameOrMobile: '',
                PageUrl: ''
            }
        },
        methods:{
            getOaRequire(){
                let self = this
                let loginNameOrMobile = this.loginNameOrMobile
                api.gaoliUnionLogin({
                    loginNameOrMobile,
                    companyCode:'10001',
                    loginSource:'E_WEB',
                    loginType:"PASSWORD"
                }).then(res => {
                    if(res.data.code === 200) {
                        const data = res.data.data
                        Utils.setSession('userInfo', data);
                        Utils.setLocalStorage('userInfo', data);
                        if(this.$route.query.PageUrl != ''){
                            self.$router.push(`/?href=${this.$route.query.PageUrl}`)
                        } else{
                            self.$router.push('/')
                        }
                    }
                })
            }
        },
        created(){
            let query = this.$route.query
            console.log(query , 'query')
            this.loginNameOrMobile = query.UserCode
            this.$store.dispatch('setOaQuery', query)
            this.getOaRequire()
        }
    }
</script>

<style scoped>

</style>